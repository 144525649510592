#ContactForm {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center horizontally */
    justify-content : center;/* Center vertically */
    color: blue;
    min-height: 80vh;
    font-weight: bolder;
    font-size: 20px;  								  																			
}

.contact {  
    width: 100%; /* Full width for responsiveness */
}


.contact label {
    background-color: #fff;
    opacity: 0.8;
    padding: 10px; /* Add padding for better spacing */
    border-radius: 5px; /* Slightly rounded corners */
}

.contact input,
.contact textarea {
    width: 50%; /* Full width */
    padding: 10px; /* Spacing inside inputs */
    border: 1px solid #ccc; /* Border styling */
    border-radius: 5px; /* Slightly rounded corners */
    font-size: inherit; /* Inherit font size */
}

.contact input:focus,
.contact textarea:focus {
    border-color: blue; /* Change border color on focus */
    outline: none; /* Remove default outline */
}

.message {
    height: 100px;
    resize: none; /* Prevent resizing */
}

.submit {
    background-color: blue; /* Button background */
    width: 50%;
    color: white; /* Text color */
    padding: 10px 15px; /* Spacing */
    border: none; /* Remove default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 1.2rem; /* Increase font size */
}

.submit:hover {
    background-color: darkblue; /* Darken button on hover */
}
		
												   
								  
									 
											 
											 
												  
											   
 

			  
															
 
