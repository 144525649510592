.footer {
  position: fixed; /* Keeps footer at the bottom */     
  bottom: 0;
  left: 0; /* Ensure it starts from the left */
  width: 100%; /* Full width */
  height: 10vh; /* Fixed height */
  display: flex; /* Use Flexbox for alignment */
  background-color: rgb(220, 120, 140, 0.9); /* Background color*/
  padding: 0 1rem; /* Horizontal padding */
  font-size: 1rem; /* Font size */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}
