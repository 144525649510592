.navbar {
  align-items: center;
  display: flex;
  background-color: rgb(220, 120, 140, 0.9);
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  box-shadow: 0 -2px 5px #0000001a; /* Optional shadow for depth */
  min-height: 10vh;
}

.navbar-left .logo {
  color: #000000;
  font-weight: bolder;
  text-decoration: none;
}
.navbar-center {
  position: relative;
  left: 40%;
  margin-top: -5px;
  margin-left: -50px; 
  color: #fff;
  padding: 1rem;
}
.navbar-center .nav-links {
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar-center .nav-links li {
  margin-right: 1rem;
}

.navbar-center .nav-links a {
  color: #000000;
  font-weight: bolder;
  text-decoration: none;
}

/* Hamburger button styling */
nav {
  background-color: rgb(220, 120, 140, 0.9);;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
nav button {
  display: none;
}

/* Style adjustments for smaller screens */
@media (max-width: 768px) {
  nav a {
    display: grid;
    color: #000000;
  }

  nav button {
    display: grid;
  }

  /* If the menu is open, we'll display the links */
  .isOpen a {
    display: grid;
  }

}