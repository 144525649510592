#body {
  width: 100%; 
  height: calc(100vh - 24vh);
  overflow: hidden;
  position: relative;
  background: linear-gradient(to right, rgba(250, 164, 178, 0.9), rgba(255, 238, 186, 0.9));
}

.welcome {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center horizontally */
  justify-content : center;/* Center vertically */
  min-height: 80vh; /* Full height of the viewport */
  color: #333; /* Text color */
  text-align: center; /* Center text */
  padding: 20px; /* Add some padding */
}

.welcome h1 {
  margin: 10px 0; /* Space between headings */
  font-family: 'Arial', sans-serif; /* Choose a font family */
  font-weight: bold; /* Make it bold */
}

.photo-container {
  display: flex;
  justify-content: center; /* Center the photo horizontally */
  margin: 20px 0; /* Add some space around the photo */
}

.my-photo {
  width: 150px; /* Adjust the size */
  height: 150px; /* Ensure it stays square */
  border-radius: 50%; /* Make it circular */
  object-fit: cover; /* Ensure the image covers the circle */
  border: 3px solid #333; /* Optional: add a border around the photo */
}

/* Styling for the links container */
.links-container {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adds space between the icons */
  margin-top: 20px;
}

.links-container a {
  color: inherit; /* Ensure the icon colors are inherited */
  text-decoration: none; /* Remove underline from links */
}

.links-container i {
  transition: color 0.3s; /* Smooth hover effect */
}

.links-container a:hover i {
  color: #0077b5; /* Change color on hover for LinkedIn */
}

.links-container a:hover .fa-github {
  color: #1d034e; /* Change color on hover for GitHub */
}

.email-container {
  margin-top: 20px;
}

.email-link {
  font-size: 1.2rem;
  color: #0077b5; /* Color to match LinkedIn */
  text-decoration: none;
  font-weight: bold;
}

.email-link:hover {
  color: #004a8d; /* Darker color on hover */
  text-decoration: underline;
}

.skill-menu {
  margin-top: 35px;
  padding: 20px; /* Add padding for better spacing */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  max-height: 70vh;
  overflow-y: auto;
  padding-right: 15px;
}

.skill {
  display: grid;
  place-items: center;
  margin-bottom: 20px; /* Space between accordion items */
}

.skill,
button {
  font-size: 30px; /* Keep larger font size */
  border-color: #fff;
}

.skill-links {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.skill-links li {
  display: block; /* Change to block for full width */
  padding: 10px 5px; /* Add padding for better click area */
  font-weight: bold;
					 
  font-size: 20px;
  border: 1px solid black; /* Solid border for clarity */
					  
  background-color: #fff; /* White background */
				
  color: blue;
  transition: background-color 0.3s; /* Smooth transition for hover */
  margin-bottom: 10px; /* Space between items */
  border-radius: 4px; /* Rounded corners */
}

.tippy-box {
  background-color: rgba(51, 51, 51, 0.9);/* Background color */
  color: #fff; /* Text color */
  border-radius: 4px; /* Rounded corners */
  padding: 10px; /* Padding */
  font-size: 14px; /* Font size */
  max-width: 200px; /* Max width */
}

.tippy-arrow {
  color: #333; /* Arrow color matching the background */
}

.skill-links li:hover {
  background-color: rgba(0, 0, 255, 0.1); /* Light blue on hover */
  cursor: pointer;
}

.skill-links li:focus {
  outline: 2px solid blue; /* Visible focus outline */
}


.accordion-content {
  overflow: hidden; /* Hide overflow for smooth transitions */
  transition: max-height 0.3s ease, opacity 0.3s ease; /* Animate max-height and opacity */
  max-height: 0; /* Start collapsed */
  opacity: 0; /* Start hidden */
}

.accordion-item.expanded .accordion-content {
  max-height: 500px; /* Set a reasonable max height for expanded state */
  opacity: 1; /* Fade in the content */
}


@media (max-width: 768px) {
  .skill-links li {
    font-size: 18px; /* Smaller font size for mobile */
  }

  .skill {
    font-size: 24px; /* Smaller font size for headers */
  }

  .tippy-box {
    max-width: 150px; /* Smaller tooltip on mobile */
  }

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Style adjustments for smaller screens */
@media (max-width: 768px) {
  #body {
    width: 100%; 
    height: calc(100vh - 18vh);
  }

}